import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    badge: 'm-2 h-8 bg-white px-2 py-1',
    swatchCount: 'mono'
  },
  mediaSize: {
    width: 340,
    height: 340
  },
  ratingSize: 'xs',
  showSwatchCount: true
} satisfies RecursivePartial<ProductConfig['card']>
