import type { ApiConfig } from '#types/config/api'

// this config is needed in order to match the APIs cookie name
export default {
  brandPrefix: 'TBL',
  googleSiteVerification: {
    'en-ca': 'MMaoWpZCLuP4utjFSZQh6CWalMuj1s5AvQP2QLsIeLI',
    'fr-ca': 'MMaoWpZCLuP4utjFSZQh6CWalMuj1s5AvQP2QLsIeLI'
  }
} as ApiConfig
