import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  maxPanelSize: {
    y: 'calc(100% - 2.5rem)'
  },
  brandClasses: {
    content: {
      y: 'pb-10'
    }
  }
} satisfies RecursivePartial<VfConfig['panel']>
