import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  variants: ['primary', 'secondary', 'primary-inverse', 'secondary-inverse'],
  sizes: ['tiny', 'xs', 'sm'],
  darkVariants: {
    primary: 'primary-inverse',
    secondary: 'secondary-inverse'
  },
  fallbackVariants: {
    'tertiary': 'secondary',
    'tertiary-inverse': 'secondary-inverse'
  },
  fallbackSizes: {
    md: 'sm'
  }
} satisfies RecursivePartial<VfConfig['button']>
