import type { ApiConfig } from '#types/config/api'

export default {
  skipInventoryCheck: [
    'de-lu',
    'en-ie',
    'fr-lu',
    'nl-lu',
    'pt-pt'
  ]
} satisfies ApiConfig['brandify']
