import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rectangle: {
      cross: 'b b-grey-50 rounded bg-white/40 bg-slash dark:bg-black/40',
      swatch: 'rounded hover:(ring-2 ring-black dark:ring-white)',
      swatchActive: 'rounded ring-2 ring-black dark:ring-white',
      swatchInner: 'rounded'
    }
  },
  brandStyles: {
    rectangle: {
      container: {
        h: {
          sm: '5.5rem',
          md: '6.5rem',
          lg: '6.5rem'
        },
        w: {
          sm: '5.5rem',
          md: '6.5rem',
          lg: '6.5rem'
        }
      },
      cross: {
        inset: {
          sm: '0.25rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatch: {
        inset: {
          sm: '0.25rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatchUnavailable: {
        inset: {
          sm: '0.5rem',
          md: '0.5rem',
          lg: '0.5rem'
        }
      }
    }
  },
  config: {
    thumbnail: {
      rectangle: {
        height: 96,
        width: 96
      }
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
