import { getRequestProtocol } from 'h3'

export const useHost = () => {
  const { host } = import.meta.server ? useRequestHeaders() : window.location

  return host
}

export const useProtocol = () =>
  import.meta.server ? getRequestProtocol(useRequestEvent()!) : window.location.protocol.replace(':', '')

export const useOrigin = () => `${useProtocol()}://${useHost()}`

export const useSubdomain = () => {
  const host = useHost()
  if (!host.includes('.')) return ''
  const hostSegments = host.split('.')
  const subdomainTypes = ['www', 'preprod', 'qa', 'dev']
  const hostSubdomain = hostSegments.find((segment) => subdomainTypes.includes(segment.replace(/\d/g, '')))
  return hostSubdomain || ''
}

export const useTld = () => {
  const host = useHost()
  return host.includes('.') ? host.split('.')[host.split('.').length - 1] : ''
}
