<script lang="ts">
import { PopoverContentContextKey } from './context'

export default defineComponent({
  props: {
    /**
     * The element or component the PopoverContent should render as.
     */
    tag: {
      type: String,
      default: 'div'
    }
  },
  setup(props) {
    const { side, middlewareData, arrow: arrowRef, arrowShift } = inject(PopoverContentContextKey)!

    return () => {
      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right'
      }[side.value]

      const { arrow } = middlewareData.value

      return h(
        props.tag,
        {
          ref: arrowRef,
          style: {
            top: arrow?.y != null ? `${arrow?.y}px` : '',
            left: arrow?.x != null ? `${arrow?.x}px` : '',
            right: '',
            bottom: '',
            [staticSide]: `${arrowShift.value}px`
          }
        }
      )
    }
  }
})
</script>
