import type { CheckoutConfig } from '#types/config/checkout'

export default {
  version: 10,
  merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  requiredBillingContactFields: ['email', 'name', 'phone', 'postalAddress'],
  requiredShippingContactFields: ['email', 'name', 'phone', 'postalAddress'],
  applePayDisclaimerUrl: 'https://www.apple.com/apple-pay/'
} satisfies CheckoutConfig['applePay']
