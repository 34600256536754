import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  lockBodyScroll: true,
  showImageSearch: true,
  searchThreshold: 3,
  maxTopSearches: 3,
  iconSize: 'lg',
  mobileNavIconSize: 'lg',
  brandClasses: {
    button: 'rounded-r-sm bg-brand-2 c-white',
    form: '<lg:bg-grey-90',
    suggestions: {
      wrapper: 'lg:shadow-lg',
      results: 'py-4',
      title: 'title-4 md:title-3'
    }
  }
} satisfies RecursivePartial<VfConfig['search']>
