import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandStyles: {
    container: {
      'min-w': {
        sm: '100%',
        md: '18rem',
      }
    }
  },
  buttonSize: 'md'
} satisfies RecursivePartial<ProductConfig['pagination']>
