import type { CMSConfig } from '#types/config/components/cms'

export default {
  componentSpacingMap: {
    default: '4rem',
    none: '0',
    xsmall: '1rem',
    small: '1.5rem',
    medium: '2.5rem',
    large: '4rem',
    xlarge: '5rem'
  }
} satisfies CMSConfig['section']
