import type { RecursivePartial } from '#types/utils'
import type { TabsConfig } from '#types/config/components/vf/tabs'

export default {
  brandClasses: {
    primary: {
      triggerActive: ['fw-bold']
    },
    secondary: {
      triggerContainer: 'my-5 f-col justify-center gap-x-2 gap-y-4 md:flex-row',
      trigger: 'vf-button vf-button-primary vf-button-sm flex-1',
      triggerActive: 'bg-grey-20'
    }
  }
} satisfies RecursivePartial<TabsConfig>
