/**
 * Checks if value is a boolean.
 * @example
 * isBoolean(false)
 * returns true
 * @category Utils
 */
export const isBoolean = (val: any): val is boolean => typeof val === 'boolean'

/**
 * Checks if value is a function.
 * @example
 * const helloWorld = () => 'hello world'
 * isFunction(helloWorld)
 * returns true
 * @category Utils
 */
export const isFunction = <T extends Function> (val: any): val is T => typeof val === 'function'

/**
 * Checks if value is a number.
 * @example
 * isNumber(5)
 * returns true
 * @category Utils
 */
export const isNumber = (val: any): val is number => typeof val === 'number'

/**
 * Checks if value is a string.
 * @example
 * isString('hello world')
 * returns true
 * @category Utils
 */
export const isString = (val: unknown): val is string => typeof val === 'string'

/**
 * Checks if value is an object.
 * @example
 * isObject({ foo: 'bar' })
 * returns false
 * @category Utils
 */
export const isObject = (val: any): val is object => toString.call(val) === '[object Object]'

/**
 * Checks if value is an HTMLElement.
 * @category Utils
 */
export const isHTMLElement = (val: any): val is HTMLElement => val instanceof HTMLElement

/**
 * Checks if value is an HTMLElement.
 * @category Utils
 */
export const isSVGElement = (val: any): val is SVGElement => val instanceof SVGElement
