import type { FormConfig } from '#types/config/components/form'

/*
* The in store pickup for guest and logged in user
* email required - US and CA
*/
export default {
  email: {
    requiredLocales: ['en-ca', 'en-us', 'fr-ca']
  },
} satisfies FormConfig['storePickup']
