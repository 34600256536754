// Card Definitions: GLOBAL15-54732
// card_type   => provided by MuleSoft
// name        => provided by MuleSoft      (Cybersource "card.brandedName")
// id          => formatted/abbreviated name (Cybersource "card.name")

import type { CreditCardConfig, CreditCardType } from '#types/creditCard'

// description => provided by MuleSoft      (Cybersource "card.cybsCardType")
export const CreditCardTypes: {
  [T in CreditCardType]: CreditCardConfig<T>
} = {
  AMEX: { card_type: 'AMEX', name: 'American Express', id: 'amex', description: '003' },
  DINERS: { card_type: 'DINERS', name: 'Diners Club International', id: 'diners-club', description: '005' },
  DISCOVER: { card_type: 'DISCOVER', name: 'Discover', id: 'discover', description: '004' },
  JCB: { card_type: 'JCB', name: 'JCB Card', id: 'jcb', description: '007' },
  MAESTRO: { card_type: 'MAESTRO', name: 'Maestro', id: 'maestro', description: '042' },
  MASTERCARD: { card_type: 'MASTERCARD', name: 'Master Card', id: 'mastercard', description: '002' },
  UNIONPAY: { card_type: 'UNIONPAY', name: 'China Union Pay', id: 'cup', description: '062' },
  VISA: { card_type: 'VISA', name: 'Visa', id: 'visa', description: '001' }
}
