import type { SignUpConfig } from '#types/config/components/form/signUp'
import type { RecursivePartial } from '#types/utils'

export default {
  agreementCheckboxes: [
    {
      type: 'termsConditionsAndPolicy',
      locales: [
        'de-at',
        'de-be',
        'de-ch',
        'de-lu',
        'en-ch',
        'en-dk',
        'en-ie',
        'en-se',
        'fr-be',
        'fr-ch',
        'fr-lu',
        'it-ch',
        'nl-be',
        'nl-lu',
        'pt-pt',
      ],
      required: true
    },
    {
      type: 'loyaltyTerms',
      locales: ['de-de', 'en-gb', 'es-es', 'fr-fr', 'it-it', 'nl-nl'],
      required: true
    },
    {
      type: 'newsletter',
      locales: [
        'de-at',
        'de-be',
        'de-ch',
        'de-de',
        'de-lu',
        'en-ca',
        'en-ch',
        'en-dk',
        'en-gb',
        'en-ie',
        'en-se',
        'en-us',
        'es-es',
        'es-us',
        'fr-be',
        'fr-ca',
        'fr-ch',
        'fr-fr',
        'fr-lu',
        'it-ch',
        'it-it',
        'nl-be',
        'nl-lu',
        'nl-nl',
        'pt-pt',
      ]
    },
    {
      type: 'loyaltyTerms',
      locales: ['en-us', 'es-us'],
      required: true
    },
    {
      type: 'policy',
      locales: ['en-us', 'es-us', 'en-ca', 'fr-ca'],
      required: true
    }
  ],
  gender: {
    options: [
      { value: 'M', label: 'male' },
      { value: 'F', label: 'female' },
      { value: 'NA', label: 'preferNotToAnswer' }
    ],
    requiredLocales: [
      'de-at',
      'de-be',
      'de-ch',
      'de-de',
      'de-lu',
      'en-ch',
      'en-dk',
      'en-gb',
      'en-ie',
      'en-se',
      'es-es',
      'fr-be',
      'fr-ch',
      'fr-fr',
      'fr-lu',
      'it-ch',
      'it-it',
      'nl-be',
      'nl-lu',
      'nl-nl',
      'pt-pt',
    ]
  },
  loyaltyAgreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['en-us', 'es-us'],
      required: true
    },
    {
      type: 'loyaltyTerms',
      locales: ['de-de', 'en-gb', 'es-es', 'fr-fr', 'it-it', 'nl-nl'],
      required: true
    }
  ],
  minimalAge: 18,
  onSuccessNavigateTo: '/account',
  showBirthDateField: true,
  showSmsSectionLocales: ['en-us', 'es-us', 'en-ca', 'fr-ca'],
  smsCheckboxSize: 'sm',
} satisfies RecursivePartial<SignUpConfig>
