import type { RecursivePartial } from '#types/utils'
import type { FormConfig } from '#types/config/components/form'

/*
* The in store pickup for guest and logged in user
* email required - US and CA
*/

export default {
  email: {
    requiredLocales: ['en-ca', 'en-us', 'es-us', 'fr-ca']
  },
} satisfies RecursivePartial<FormConfig['storePickup']>
