import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    color: 'white',
    disableMaxWidth: true,
    height: 48
  }
} satisfies RecursivePartial<PaypalConfig['button']>
