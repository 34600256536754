export default (host: string) => ({
  '@id': 'organization',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'USA',
    'addressLocality': 'Stratham',
    'addressRegion': 'NH',
    'postalCode': '03885',
    'streetAddress': '200 Domain Drive Stratham, NH 03885'
  },
  'areaServed': 'US',
  'brand': {
    '@type': 'Brand',
    '@id': `https://${host}/en-us#brand`,
    'name': 'Timberland',
    'description': 'Timberland is Built for the Bold. We inspire and equip adventurers to get outside, work hard and move the world forward.',
    'url': `https://${host}/en-us`,
    'image': `https://${host}/img/logo50.svg`
  },
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'telephone': '+1-888-802-9947',
      'contactType': 'phone support',
      'areaServed': 'US',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 07:00-18:00 CST'
    },
    {
      '@type': 'ContactPoint',
      'telephone': '+1-920-545-5123',
      'contactType': 'text support',
      'areaServed': 'US',
      'hoursAvailable': [
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '18:00:00 CST',
          'dayOfWeek': 'https://schema.org/Monday',
          'opens': '07:00:00'
        },
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '18:00:00 CST',
          'dayOfWeek': 'https://schema.org/Tuesday',
          'opens': '07:00:00'
        },
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '18:00:00 CST',
          'dayOfWeek': 'https://schema.org/Wednesday',
          'opens': '07:00:00'
        },
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '18:00:00 CST',
          'dayOfWeek': 'https://schema.org/Thursday',
          'opens': '07:00:00'
        },
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '18:00:00 CST',
          'dayOfWeek': 'https://schema.org/Friday',
          'opens': '07:00:00'
        },
        {
          '@type': 'OpeningHoursSpecification',
          'closes': '17:00:00 CST',
          'dayOfWeek': 'https://schema.org/Saturday',
          'opens': '08:30:00'
        }
      ]
    },
    {
      '@type': 'ContactPoint',
      'contactType': 'online chat',
      'areaServed': 'US',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 07:00-18:00 CST',
      'url': `https://${host}/en-us`
    }
  ],
  'logo': {
    '@id': '#logo',
    'caption': 'Timberland',
    'contentUrl': `https://${host}/img/logo50.svg`,
    'url': `https://${host}/img/logo50.svg`
  },
  'name': 'Timberland',
  'parentOrganization': 'VF Corporation (VFC)',
  'sameAs': [
    'https://www.youtube.com/timberland',
    'https://www.instagram.com/timberland',
    'https://www.facebook.com/timberland'
  ],
  'url': `https://${host}/en-us`
})
