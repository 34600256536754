import { CreditCardTypes } from '#commerce/utils/creditCardTypes'
import type { CheckoutConfig } from '#types/config/checkout'
import type { RecursivePartial } from '#types/utils'

// card image paths defined in /domains/commerce/utils/credit-card/getCreditCardImage.ts
// assign in preferred order for displaying card images
export default {
  allowed: {
    GB: [
      CreditCardTypes.VISA,
      CreditCardTypes.MASTERCARD,
      CreditCardTypes.AMEX,
      CreditCardTypes.DISCOVER,
      CreditCardTypes.DINERS,
      CreditCardTypes.JCB,
      CreditCardTypes.UNIONPAY
    ]
  },
  imageGroup: {
    GB: [CreditCardTypes.VISA, CreditCardTypes.MASTERCARD, CreditCardTypes.AMEX, CreditCardTypes.DISCOVER]
  }
} satisfies RecursivePartial<CheckoutConfig['creditCards']>
