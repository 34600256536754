import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    columns: 'px-2 mt-4',
    menu: 'container pt-5',
    shopAll: 'subtitle-3',
    visualSelectors: 'mt-6 gap-20',
    visualSelectorsTitle: 'uppercase',
    visualSelectorsTitleWrapper: 'mt-6',
    underline: 'b-b-3 b-white',
    underlineActive: '!b-grey-10'
  }
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
