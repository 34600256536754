<template>
  <iframe :src data-test-id="base-iframe" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  src: string
  params?: object
}>()

const attrs = useAttrs() as { onMessage: () => void }

const src = computed(() => {
  const _src = new URL(props.src)
  if (props.params)
    Object.entries(props.params).forEach(([key, value]) => _src.searchParams.set(key, value))
  return _src.href
})

if (attrs.onMessage) {
  onMounted(() => window.addEventListener('message', attrs.onMessage))
  onBeforeUnmount(() => window.removeEventListener('message', attrs.onMessage))
}
</script>
