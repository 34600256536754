export default (host: string) => ({
  '@id': 'organization',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'CH',
    'addressLocality': 'Stabio',
    'addressRegion': 'Ticino',
    'postalCode': '6855',
    'streetAddress': 'Via Laveggio 5'
  },
  'areaServed': 'CH',
  'brand': {
    '@type': 'Brand',
    '@id': `https://${host}/fr-ch#brand`,
    'name': 'Timberland',
    'description': 'Timberland est une marque conçue pour les audacieux. Nous inspirons et équipons les personnes en quête d\'aventure afin d\'explorer le monde extérieur, travailler dur et faire avancer le monde.',
    'url': `https://${host}/fr-ch`,
    'image': `https://${host}/img/logo50.svg`
  },
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'telephone': '0800 001 926',
      'contactType': 'Telephone',
      'areaServed': 'CH',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CEST'
    },
    {
      '@type': 'ContactPoint',
      'contactType': 'Chat',
      'areaServed': 'CH',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CEST',
      'url': `https://${host}/fr-ch`
    }
  ],
  'logo': {
    '@id': '#logo',
    'caption': 'Timberland',
    'contentUrl': `https://${host}/img/logo50.svg`,
    'url': `https://${host}/img/logo50.svg`
  },
  'name': 'Timberland',
  'parentOrganization': 'VF Corporation (VFC)',
  'sameAs': [
    'https://www.youtube.com/timberland',
    'https://www.instagram.com/timberland',
    'https://www.facebook.com/timberland'
  ],
  'url': `https://${host}/fr-ch`
})
