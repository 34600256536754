import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  iconSize: 'md',
  sort: {
    brandClasses: {
      label: 'h-10 b rounded-3xl',
      text: 'text-sm pl-4 pr-10'
    }
  }
} satisfies RecursivePartial<VfConfig['form']['select']>
