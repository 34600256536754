export default {
  /**
    ^ asserts position at start of the string
    . matches any character (except for line terminators)
    {2} matches the previous token exactly 2 times
    1st Capturing Group (.{6})
    . matches any character (except for line terminators)
    {6} matches the previous token exactly 6 times
   */
  styleId: /^.{2}(.{6})/
}
