export default {
  size: {
    'xs': 12,
    'sm': 16,
    'md': 24,
    'lg': 32,
    'xl': 40,
    '2xl': 48,
    '3xl': 56,
    '4xl': 64,
    '5xl': 72
  }
}
