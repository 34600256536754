<template>
  <component :is="tag" data-test-id="base-popover" @mouseleave="onMouseleave">
    <slot v-bind="{ opened, side, toggle, open, close }" />
  </component>
</template>

<script lang="ts" setup>
import type { Side } from '@floating-ui/vue'
import { PopoverContextKey } from './context'
import type { Props } from '#types/components/base/popover'

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  placement: 'bottom',
  arrowShift: 0,
  teleport: false,
  destroy: true
})

const emit = defineEmits<{
  close: []
}>()

defineSlots<{
  default: (props: {
    opened: boolean
    side: Side
    toggle: typeof toggle
    open: typeof open
    close: typeof close
  }) => any
}>()

const opened = defineModel({ default: false })
const initiator = ref()
const floating = ref()
const side = ref(props.placement.split('-')[0] as Side)
const { show, hide } = isObject(props.delay) ? props.delay : { show: props.delay, hide: props.delay }
let timeout

const handler = (cb: (e?: Event) => void, delay?: number) => (e?: Event) => {
  clearTimeout(timeout)
  if (!delay) return cb(e)
  timeout = setTimeout(() => cb(e), delay)
}

const forceOpen = (e) => {
  initiator.value = e?.target
  opened.value = true
}
const open = handler(forceOpen, show)

const forceClose = () => {
  opened.value = false
  emit('close')
}

const close = handler(forceClose, hide)

const toggle = (e: Event) => (opened.value ? forceClose() : forceOpen(e))

provide(PopoverContextKey, {
  opened,
  initiator,
  floating,
  side,
  forceClose,
  ...toRefs(props)
})

const onMouseleave = (e) => {
  if (!props.closeOnLeave || e.toElement === floating.value) return
  if (containsChildElement(floating.value, e.toElement)) return
  forceClose()
}

defineExpose({
  open,
  close,
  opened
})
</script>
