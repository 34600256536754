import type { ContactUsConfig } from '#types/config/components/form/contactUs'

export default {
  agreementCheckboxes: [
    {
      type: 'policy',
      required: true
    }
  ],
  category: '',
  email: '',
  firstName: '',
  lastName: '',
  message: '',
  orderNumber: '',
  phone: '',
  subject: ''
} satisfies ContactUsConfig
