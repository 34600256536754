import type { RecursivePartial } from '#types/utils'
import type { KlarnaConfig } from '#types/config/components/klarna'

export default {
  installments: {
    'en-ie': 3,
    'es-es': 3,
    'fr-fr': 3,
    'it-it': 3
  }
} satisfies RecursivePartial<KlarnaConfig>
