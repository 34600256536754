import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rectangle: {
      cross: 'b b-grey-50 bg-white/60 bg-cross',
      swatch: 'hover:(ring ring-grey-10 ring-inset)',
      swatchActive: 'ring ring-grey-10 ring-inset'
    },
    round: {
      cross: 'b b-grey-50 bg-white/60 bg-cross',
      swatch: 'hover:(ring ring-grey-10 ring-inset)',
      swatchActive: 'ring ring-grey-10 ring-inset',
      swatchInner: 'rounded-full b b-grey-70'
    }
  },
  brandStyles: {
    rectangle: {
      container: {
        h: {
          sm: '3rem',
          md: '4.5rem',
          lg: '4.5rem'
        },
        w: {
          sm: '3rem',
          md: '4.5rem',
          lg: '4.5rem'
        }
      },
      cross: {
        inset: {
          sm: '0.1875rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatch: {
        inset: {
          sm: '0.1875rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatchUnavailable: {
        inset: {
          sm: '0.4375rem',
          md: '0.5rem',
          lg: '0.5rem'
        }
      }
    },
    round: {
      container: {
        h: {
          sm: '2.5rem',
          md: '2.5rem',
          lg: '3rem'
        },
        p: {
          sm: '0.5rem',
          md: '0.25rem',
          lg: '0.25rem'
        },
        w: {
          sm: '2.5rem',
          md: '2.5rem',
          lg: '3rem'
        }
      },
      cross: {
        inset: {
          sm: '0.1875rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatch: {
        inset: {
          sm: '0.1875rem',
          md: '0.25rem',
          lg: '0.25rem'
        }
      },
      swatchUnavailable: {
        inset: {
          sm: '0.3125rem',
          md: '0.375rem',
          lg: '0.375rem'
        }
      }
    }
  },
  config: {
    thumbnail: {
      format: 'png',
      rectangle: {
        height: 64,
        width: 64
      },
      round: {
        height: 24,
        width: 24
      }
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
