import type { RecursivePartial } from '#types/utils'
import type { ProductUpsellConfig } from '#types/config/components/product/upsell'

export default {
  brandClasses: {
    container: 'bg-brand-6 rounded-lg p-4 dark:bg-grey-10',
    details: {
      button: 'underlined',
      image: 'rounded overflow-hidden',
      price: 'elevate-body-4',
      text: 'elevate-body-3'
    },
    title: 'elevate-title-4'
  },
  checkboxSize: 'md',
  detailsLinkSize: undefined,
  image: {
    height: 80,
    width: 80
  }
} satisfies RecursivePartial<ProductUpsellConfig>
