import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  brandClasses: {
    ctasWrapper: 'mt-8 b-t b-grey-70 pb-8 pt-4 space-y-4',
    grid: 'mb-12 md:(grid mb-8 items-start gap-4 cols-12)',
    heading: 'pt-4 lg:col-span-4 md:col-span-5 <lg:mb-4',
    headingProductName: 'text-lg lg:text-2xl',
    headingSticky: 'mb-8 lg:mb-4',
    gallery: 'lg:col-span-8 md:(col-span-7 row-start-1 row-end-3 pt-4)',
    details: 'lg:col-span-4 md:(col-span-5 row-start-2 row-end-5)',
    detailsSticky: 'md:pt-4',
    shopTheLookHeading: 'text-center',
    shopTheLookWrapper: 'mt-5',
    shopTheLookCta: 'justify-center',
    eSpotsAndReviews: 'mt-4 md:mt-8 space-y-8',
    gridPartialWidth: 'lg:col-span-8 md:col-span-7',
    gridFullWidth: 'col-span-12'
  },
  reviews: {
    nonFilterKeys: [],
    pageSize: 6,
    mostLikedCommentTextThreshold: 225,
    recommendationRateThreshold: 74,
    showProsAndConsSnapshot: false,
    filterOptionsMaxNumber: 7,
    summaryHeight: '5.2rem'
  },
  seoMetaImage: 'https://images.timberland.com/is/image/timberland/A17KC001-HERO',
  tryItOn: {
    apparelUrl: 'https://sneaker-window.vyking.io/vyking-assets/customer/{brand}/ar-asset-packages/{productId}/offsets.json',
    config: '/vyking/modeld-2.bin',
    key: 'com.timberland',
    src: 'https://sneaker-window.vyking.io/vyking-apparel/1/vyking-apparel.js'
  },
  questions: {
    pageSize: 6
  },
} satisfies RecursivePartial<PdpConfig>
