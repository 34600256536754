import type { RecursivePartial } from '#types/utils'
import type { ProductUpsellConfig } from '#types/config/components/product/upsell'

export default {
  brandClasses: {
    container: 'bg-brand-6 px-4 pb-4 pt-2',
    details: {
      button: 'text-sm underlined'
    },
    title: 'subtitle-3'
  },
  detailsLinkSize: 'sm'
} satisfies RecursivePartial<ProductUpsellConfig>
