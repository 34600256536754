import type { FormConfig } from '#types/config/components/vf/form'

export default {
  brandClasses: {
    borderColor: 'b-grey-40',
    text: 'text-base',
    textSelected: 'c-grey-10',
    icon: 'mr-2'
  },
  iconSize: 'lg',
  sort: {
    brandClasses: {
      label: 'h-10 b rounded-sm',
      icon: 'absolute inset-y-0 right-0 my-a mr-2',
      text: 'pl-3 pr-10 text-base'
    }
  }
} satisfies FormConfig['select']
