import type { VfConfig } from '#types/config/components/vf'

export default {
  sizes: {
    full: {
      width: 120,
      height: 22
    },
    pro: {
      width: 38,
      height: 32
    },
    default: {
      width: 40,
      height: 40
    }
  }
} satisfies VfConfig['logo']
