<template>
  <input
    v-model="model"
    data-test-id="base-input"
    :aria-describedby="ariaDescribedby"
    @change="$emit('change', ($event?.target as HTMLInputElement)?.value)"
  >
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Identifies the element (or elements) that describes the object.
   * Needs to be defined explicitly as a prop so the dash can be kept
   * in the correct place for the input attribute
   */
  ariaDescribedby?: string
}>()

defineEmits<{ change: [value: string] }>()

const model = defineModel<string | number>()

defineExpose(getCurrentInstance()?.proxy!)
</script>
