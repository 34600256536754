import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'rounded-sm text-xs px-2',
    disabled: 'bg-grey-80 b-grey-40 c-grey-30',
    selected: 'c-grey-10 bg-white b-grey-10',
    sizeMd: 'min-h-10 min-w-10',
    sizeSm: 'min-h-8 min-w-8',
    unavailable: 'bg-cross-hollow bg-grey-80 b-grey-40 hover:b-grey-10 c-grey-20',
    unavailableSelected: 'bg-cross-hollow bg-grey-80 b-grey-10 c-grey-10',
    unselected: 'bg-white b-grey-40 c-grey-20 hover:b-black'
  },
  productAttributeSelectorSize: {
    fitType: 'md',
    width: 'md',
    length: 'md',
    size: 'md'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
