import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'rounded-sm px-1 text-xs',
    rectangle: 'px-3.5',
    selected: 'bg-brand-1 b-brand-1 c-grey-10'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
