import type { DropdownConfig } from '#types/config/components/vf/dropdown'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    button: 'rounded-sm bg-white pl-2 pr-1',
    buttonOpened: 'b-grey-70 b-b-white rounded-b-none',
    popoverContent: 'ring'
  }
} satisfies RecursivePartial<DropdownConfig>
