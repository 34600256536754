import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    container: 'p-4 bg-brand-6',
    details: {
      button: 'underlined',
      price: 'text-sm'
    },
    title: 'text-md'
  },
  checkboxSize: 'sm',
  image: {
    height: 63,
    width: 63
  },
  buttonSize: 'xs',
  buttonVariant: 'secondary'
} satisfies ProductConfig['upsell']
