export default {
  EMEA: {
    baseUrl: {
      DEV: 'https://ds8s3etqpk8ol.cloudfront.net',
      QA: 'https://d1h1e9rt6vsq3c.cloudfront.net',
      PREPROD: 'https://dghi27fisgxty.cloudfront.net',
      PROD: 'https://d3kvd9ktmn6p4t.cloudfront.net'
    },
    apiDomain: {
      DEV: 'https://timberlandemea-staging.demostellar.com',
      QA: 'https://timberlandemea-staging.demostellar.com',
      PREPROD: 'https://timberlandemea-staging.demostellar.com',
      PROD: 'https://timberlandemea.runstellar.com'
    },
    cssUrl: '/tblcanvasemea/tblcanvasemea-webapp.style.css',
    jsUrl: '/loyalty-webapp.bundle.js',
    configUrl: '/tblcanvasemea/app-config.json'
  },
  NORA: {
    baseUrl: {
      DEV: 'https://d3bp57v33hncyl.cloudfront.net',
      QA: 'https://dpnuyk3swzpxc.cloudfront.net',
      PREPROD: 'https://d2ja1t4z0bvzku.cloudfront.net',
      PROD: 'https://d3fwuswwhi7ss1.cloudfront.net'
    },
    apiDomain: {
      DEV: 'https://cust1297.teststellar.com',
      QA: 'https://cust1297.teststellar.com',
      PREPROD: 'https://cust1297.teststellar.com',
      PROD: 'https://cust1297.cheetahedp.com'
    },
    cssUrl: '/tblnora/tblnora-webapp.style.css',
    jsUrl: '/loyalty-webapp.bundle.js',
    configUrl: '/tblnora/app-config.json'
  }
}
