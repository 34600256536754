/*
- The value we send has to match the backend key. They are used for tagging content with email preferences and user segmenting so we cannot change them.
- In 1.5 this is fully configured in the CMS but we are decoupling this for Canvas.
- There is also no endpoint to retrieve these options so we are configuring them here.
- Keeping this as a keys until we have confirmation this will be pulled from the CMS at some point
- These keys are mapped exactly to the translation files to interestsOptions object
*/
import type { RecursivePartial } from '#types/utils'
import type { InterestsConfig } from '#types/config/components/form/interests'

export default {
  brandClasses: {
    wrapper: 'grid gap-4',
    wrapperDivided: 'lg:cols-2'
  },
  component: 'checkbox',
  options: [
    'exploring_the_city',
    'enjoying_the_outdoors',
    'relaxing_with_friends',
    'jobsite_performance',
    'environment_and_sustainability',
    'street_style'
  ]
} satisfies RecursivePartial<InterestsConfig>
