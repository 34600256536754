import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    filterAndSortCtaIcon: 'ml-1',
    innerGridWrapper: 'pb-16',
    noResults: 'mb-14 mt-12 text-center lg:mt-10 title-2',
    outerGrid: 'pt-6 container',
    pagination: 'mt-12',
    stickyHeader: 'children:(b-grey-70 b-b)',
    stickyHeaderStuck: 'shadow-bottom children:b-transparent'
  },
  eagerLoadedCards: 12,
  filterAndSortCta: {
    size: 'xs',
    variant: 'tertiary'
  },
  filtersPanelPosition: 'left',
  grid: {
    cols: {
      sm: 2,
      md: 3,
      lg: 4
    },
    gaps: {
      x: { sm: 'sm' },
      y: { sm: 'md', md: 'lg', lg: 'xl' }
    }
  },
  productsPerPage: 48,
  sideFilterOpenFacets: 2
} satisfies ProductConfig['list']
