import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    carousel: 'lg:gap-4',
    pagination: 'mb-6 mt-4 lg:hidden'
  },
  controlslist: '',
  showPagination: true
} satisfies VfConfig['mediaGallery']
