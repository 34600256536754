import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    itemSummaryHeading: 'subtitle-7 b-grey-50 <md:(b-t pt-8) md:(b-b pb-2)',
  },
  overrideNarvar: {
    redirectLocale: {
      'nl-lu': 'en-lu'
    }
  }
} satisfies RecursivePartial<DetailsItemConfig>
