<template>
  <template v-if="slotTokens">
    <template v-for="{ name, args, item } in slots">
      <slot v-if="$slots[name]" :name="name" v-bind="{ args }" />
      <template v-else>
        {{ item }}
      </template>
    </template>
  </template>
  <template v-else>
    {{ content }}
  </template>
</template>

<script lang="ts" setup>
const props = defineProps<{ content: string }>()
defineSlots<Record<string, (props: { args: string[] }) => void>>()

const slotTokens = computed(() => props.content.match(/\{(.*?)\}/g)?.map((item) => item.replace(/[{}]/g, '')))

const slots = computed(() => props.content.split(/[{}]/).map((item) => {
  const [name, ...args] = slotTokens.value?.find((e) => e === item)?.split('|') || []
  return { name, args, item }
}))
</script>
