import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  mobileNavIconSize: 'md',
  brandClasses: {
    suggestions: {
      wrapper: '',
      results: 'pt-4',
      title: 'elevate-body-3 fw-bold'
    },
    form: ''
  }
} satisfies RecursivePartial<VfConfig['search']>
