<template>
  <slot v-bind="{ current, pause, resume }" />
</template>

<script lang="ts" setup>
defineOptions({ inheritAttrs: false })
const props = defineProps<{ [K in 'active' | 'max' | 'interval']?: number }>()
const emit = defineEmits<{ [K in 'change' | 'pause' | 'resume']: [index: number] }>()
defineSlots<{
  default: (props: { current: number, pause: () => void, resume: () => void }) => void
}>()
const current = ref(props.active || 0)
const timer = ref<ReturnType<typeof setTimeout>>()

const next = () => {
  if (current.value + 1 > (props.max || Number.POSITIVE_INFINITY) - 1)
    current.value = 0
  else current.value++
  emit('change', current.value)
}
const start = () => timer.value = setTimeout(() => {
  next()
  start()
}, props.interval || 5000)
const pause = () => {
  emit('pause', current.value)
  clearTimeout(timer.value)
}
const resume = () => {
  emit('resume', current.value)
  start()
}

onMounted(start)
onBeforeUnmount(() => clearTimeout(timer.value))

defineExpose({ start, pause, resume })
</script>
