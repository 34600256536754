import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    column: 'space-y-4',
    columns: 'px-2 mt-8',
    link: 'px-3',
    title: 'subtitle-5 mt-1',
    visualSelectors: 'gap-10'
  }
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
