import type { RecursivePartial } from '#types/utils'
import type { SimplifiedEnrollmentConfig } from '#types/config/components/dialog/simplifiedEnrollment'

export default {
  chevronIconSize: 'xl',
  logoVariant: 'loyalty',
  showInterestsStep: false,
  profileToastLogo: null,
  showWelcome: false,
  skipButtonVariant: 'secondary',
} satisfies RecursivePartial<SimplifiedEnrollmentConfig>
