import type { RecursivePartial } from '#types/utils'
import type { PlpConfig } from '#types/config/pages/plp'

export default {
  seoMetaImage: 'https://images.timberland.com/is/image/timberland/placeholder-image?$496x496$',
  sorts: () => ({
    NORA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating'
    ],
    EMEA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating',
      'topSellers',
      'whatsNew'
    ]
  })
} satisfies RecursivePartial<PlpConfig>
