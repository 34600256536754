<template>
  <slot v-bind="readonly(field)" />
</template>

<script lang="ts" setup>
import { FormContextKey } from './context'
import type { Field, Rule } from '#types/components/base/form'

defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  name: string
  rule: Rule
  selector?: string | HTMLElement
}>()

defineSlots<{
  default: (props: Readonly<Field>) => void
}>()

const context = inject(FormContextKey)!
const field = context.getField(props.name)
const instance = getCurrentInstance()
const el = computed<HTMLElement>(() => ['#text', '#comment'].includes(instance?.vnode.el?.nodeName) ? instance?.vnode.el?.nextElementSibling : instance?.vnode.el)

// rules may change regarding user interaction: ie: changing the country may change the validation rules for the zip code
watch(
  () => props.rule,
  (rule) => context?.addRule({ name: props.name, rule }),
  { immediate: true }
)

whenever(() => field.value.firstInvalid && el.value, () => {
  el.value.querySelector<HTMLElement>('input, select, textarea, [tabindex]:not([tabindex="-1"')?.focus({ preventScroll: true })
  context.scrollToError && scrollToElement(props.selector || el.value)
  context.resetFirstInvalid(props.name)
})

onUnmounted(() => context?.removeRule(props.name))
</script>
