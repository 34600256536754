import type { LocaleCode } from '#types/locale'
import type { ApiConfig } from '#types/config/api'

const pickupPayload = {
  or: {
    bopis_enabled: {
      eq: '1'
    },
    sts_enabled: {
      eq: '1'
    }
  }
}

const storeIdFields = {
  'default': 'storenumber',
  'en-ca': 'enterprise_store_identifier',
  'en-us': 'enterprise_store_identifier',
  'es-us': 'enterprise_store_identifier'
}

const pickupPayloads = {
  'default': {},
  'en-ca': pickupPayload,
  'en-us': pickupPayload,
  'es-us': pickupPayload
}

const payload = (locale: LocaleCode) => ({
  and: {
    [storeIdFields[locale] || storeIdFields.default]: {
      ne: 'null'
    }
  },
  ...(pickupPayloads[locale] || pickupPayloads.default)
})

export default {
  api: {
    limit: 20,
    payload
  },
  storeIdFields
} satisfies ApiConfig['brandify']
