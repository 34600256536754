import type { PlpConfig } from '#types/config/pages/plp'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    breadcrumbs: 'my-4 text-sm',
    categoryTitle: 'elevate-title-2 lg:mb-4',
    eSpot: 'overflow-hidden b b-grey-70 rounded'
  }
} satisfies RecursivePartial<PlpConfig>
