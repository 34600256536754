/**
 * Returns values to the passed function by reducing the array of keys to an object,
 * where the key is the key from the original object, and the value is the result of the function.
 * If byKeys is set to true, it will return keys instead of values to the passed function.
 * @param obj - source object
 * @param fn - callback function which defines the mapped value
 * @param byKeys - if true, returns keys instead of values to the passed function
 * @returns - mapped object
 * @category Utils
 */
export function mapObject<T extends Record<string, any>, S>(obj: T, fn: (key: keyof T, i: number, arr: string[])
=> S, byKeys: boolean): Record<keyof T, ReturnType<typeof fn>>
export function mapObject<T extends Record<string, any>, S>(obj: T, fn: (key: T[keyof T], i: number, arr: string[])
=> S): Record<keyof T, ReturnType<typeof fn>>
export function mapObject<T extends Record<string, any>, S>(obj: T, fn: (key: keyof T, i: number, arr: string[])
=> S, byKeys?: boolean) {
  return Object.keys(obj).reduce((result, key: keyof T, i, arr) => {
    result[key] = byKeys ? fn(key, i, arr) : fn(obj[key], i, arr)
    return result
  }, {} as Record<keyof T, ReturnType<typeof fn>>)
}
