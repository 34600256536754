<template>
  <div ref="root" class="overflow-hidden">
    <slot />
  </div>
</template>

<script setup lang="ts">
const root = ref<{ children: HTMLElement[] }>()
const start = { x: 0, y: 0, distance: 0 }
const MAX_ZOOM = 4

const getDistance = ({ touches }: TouchEvent) =>
  Math.hypot(touches[0].pageX - touches[1].pageX, touches[0].pageY - touches[1].pageY)

const touchstart = (e: TouchEvent) => {
  if (e.touches.length === 2) {
    e.preventDefault()
    // Calculate where the fingers have started on the X and Y axis
    start.x = (e.touches[0].pageX + e.touches[1].pageX) / 2
    start.y = (e.touches[0].pageY + e.touches[1].pageY) / 2
    start.distance = getDistance(e)
  }
}

const touchmove = (e: TouchEvent) => {
  if (e.touches.length === 2) {
    e.preventDefault()
    // Calculate how much the fingers have moved on the X and Y axis
    const deltaX = (((e.touches[0].pageX + e.touches[1].pageX) / 2) - start.x) * 2
    const deltaY = (((e.touches[0].pageY + e.touches[1].pageY) / 2) - start.y) * 2
    const scale = Math.min(Math.max(1, getDistance(e) / start.distance), MAX_ZOOM)
    // Transform the DOM element to make it grow and move with fingers
    if (e.currentTarget)
      (e.currentTarget as HTMLElement).style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${scale})`
  }
}

const touchend = (e: TouchEvent) => {
  if (e.currentTarget)
    (e.currentTarget as HTMLElement).style.transform = ''
}

onMounted(() => {
  if (!root.value?.children?.length) return log.warn('No elements to pinch-zoom')

  for (const el of root.value?.children) {
    el.addEventListener('touchstart', touchstart)
    el.addEventListener('touchmove', touchmove)
    el.addEventListener('touchend', touchend)
  }
})
</script>
