<template>
  <slot />
</template>

<script lang="ts" setup>
import {
  useHydrateOnInteraction,
  useHydrateWhenIdle,
  useHydrateWhenVisible,
  useLazyHydration
} from 'vue3-lazy-hydration'

const props = withDefaults(defineProps<{
  when?: 'never' | 'visible' | 'interaction' | 'idle'
  interaction?: keyof HTMLElementEventMap | (keyof HTMLElementEventMap)[]
}>(), {
  interaction: () => ['touchstart', 'focus']
})

const emit = defineEmits<{ hydrated: [] }>()

const result = useLazyHydration()

if (result.willPerformHydration) {
  result.onHydrated(() => emit('hydrated'))

  if (props.when === 'idle')
    useHydrateWhenIdle(result)

  if (props.when === 'visible')
    useHydrateWhenVisible(result)

  if (props.when === 'interaction')
    useHydrateOnInteraction(result, props.interaction && [props.interaction].flat())
}
</script>
