import type { VfConfig } from '#types/config/components/vf'

export default {
  giftCardLocales: [
    'en-us',
    'en-gb',
    'es-us',
    'de-be',
    'fr-be',
    'nl-be',
    'en-dk',
    'it-it',
    'de-at',
    'en-ie',
    'fr-fr',
    'nl-nl',
    'en-se',
    'de-de',
    'fr-lu',
    'de-lu',
    'nl-lu',
    'pt-pt',
    'es-es',
  ],
  showLocationSelector: true
} satisfies VfConfig['utilityNavigation']
