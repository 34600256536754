import type { RecursivePartial } from '#types/utils'
import type { KlarnaConfig } from '#types/config/components/klarna'

export default {
  minThreshold: 35,
  maxThreshold: 1000,
  installments: {
    'default': 4,
    'en-gb': 3,
  },
  termsUrl: {
    'default': 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/{locale}/paylaterin3',
    'en-us': 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx'
  },
  imageWidth: 54,
  imageHeight: 12,
  logo: {
    pdpDialog: 'klarna'
  },
  brandClasses: {
    disclaimerText: 'text-sm c-grey-20',
    introText: 'mb-6 text-sm md:(mb-8 text-md)',
    logo: 'ml-1 <md:(h-2.5 w-a)'
  },
  showLogoHeader: false
} satisfies RecursivePartial<KlarnaConfig>
