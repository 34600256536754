import type { applePayButton } from '#types/components/checkout/applePayButton'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    icon: '!h-6 !w-16',
  },
  size: 'md',
  variant: 'primary'
} satisfies RecursivePartial<applePayButton>
