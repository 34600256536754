import { CreditCardTypes } from '#commerce/utils/creditCardTypes'
import type { CheckoutConfig } from '#types/config/checkout'

// card image paths defined in /domains/commerce/utils/credit-card/getCreditCardImage.ts
// assign in preferred order for displaying card images
export default {
  allowed: {
    CA: [
      CreditCardTypes.VISA,
      CreditCardTypes.MASTERCARD,
      CreditCardTypes.AMEX
    ],
    US: [
      CreditCardTypes.VISA,
      CreditCardTypes.MASTERCARD,
      CreditCardTypes.AMEX,
      CreditCardTypes.DISCOVER,
      CreditCardTypes.DINERS,
      CreditCardTypes.JCB,
      CreditCardTypes.UNIONPAY
    ]
  },
  imageGroup: {
    CA: [CreditCardTypes.VISA, CreditCardTypes.MASTERCARD, CreditCardTypes.AMEX],
    US: [CreditCardTypes.VISA, CreditCardTypes.MASTERCARD, CreditCardTypes.AMEX, CreditCardTypes.DISCOVER]
  },
  deviceFingerprintUrl: 'https://live.adyen.com/hpp/js/df.js?v=20171130',
  cyberSourceUrl: 'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js'
} satisfies CheckoutConfig['creditCards']
