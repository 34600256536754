import type { SignUpConfig } from '#types/config/components/form/signUp'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    heading: 'elevate-body-3 fw-bold'
  },
  buttonSize: 'xs',
  isBirthDateRequired: false,
  isSmsOptInRequired: false
} satisfies RecursivePartial<SignUpConfig>
