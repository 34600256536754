import type { Library } from '@googlemaps/js-api-loader'
import { Loader } from '@googlemaps/js-api-loader'

export default createGlobalState(() => {
  const { configGoogleApi } = useFeatureFlags()

  let googleAutocomplete, googleAutocompleteListener
  const importedLibraries: Library[] = []
  const loader = new Loader({
    apiKey: configGoogleApi,
    version: 'weekly',
    language: useLocale(),
  })

  function importLibrary(library: Library) {
    if (importedLibraries.includes(library)) return
    return loader.importLibrary(library).then(() => importedLibraries.push(library))
  }

  async function bindPlaces(
    el: HTMLInputElement,
    onPlaceChanged: (address) => void,
    componentRestrictions: google.maps.places.ComponentRestrictions
  ) {
    await importLibrary('places')
    if (googleAutocomplete) {
      window.google.maps.event.removeListener(googleAutocompleteListener)
      window.google.maps.event.clearInstanceListeners(googleAutocomplete)
    }

    googleAutocomplete = new window.google.maps.places.Autocomplete(
      el,
      {
        componentRestrictions,
        types: ['address'],
        strictBounds: false,
        fields: ['address_components']
      }
    )
    googleAutocompleteListener = googleAutocomplete?.addListener('place_changed', () => {
      let addressLine1 = ''
      let postalCode = ''
      let city = ''
      let province = ''
      let street_number = ''
      let route = ''

      googleAutocomplete.getPlace().address_components.forEach((component) => {
        switch (component.types[0]) {
          case 'street_number': {
            street_number = component.long_name
            break
          }
          case 'route': {
            route = component.short_name
            break
          }
          case 'postal_code': {
            postalCode = `${component.long_name}${postalCode}`
            break
          }
          case 'postal_code_suffix': {
            postalCode = `${postalCode}-${component.long_name}`
            break
          }
          case 'locality':
          case 'sublocality_level_1':
          case 'postal_town': {
            city = component.long_name
            break
          }
          case 'administrative_area_level_1': {
            province = component.short_name
            break
          }
        }
      })
      addressLine1 = useAppConfig().components.googlePlaces.roadFirstCountries.includes(useCountryCode())
        ? `${route} ${street_number}`
        : `${street_number} ${route}`
      onPlaceChanged?.({ addressLine1: addressLine1.trim(), postalCode, city, province })
    })
  }

  // change the restrictions after being initialized
  function restrictPlaces(componentRestrictions: google.maps.places.ComponentRestrictions) {
    if (googleAutocomplete)
      googleAutocomplete.setComponentRestrictions(componentRestrictions)
  }

  function loadMapsAndMarker() {
    return Promise.all([
      importLibrary('maps'),
      importLibrary('marker')
    ])
  }

  return {
    bindPlaces,
    restrictPlaces,
    loadMapsAndMarker,
  }
})
