import type { PagesConfig } from '#types/config/pages'

export default {
  brandClasses: {
    submitButton: 'w-full md:max-w-72'
  },
  gender: {
    options: [
      { value: 'M', label: 'male' },
      { value: 'F', label: 'female' },
      { value: 'NA', label: 'preferNotToAnswer' }
    ],
    requiredLocales: [
      'en-gb',
      'en-dk',
      'es-es',
      'en-ie',
      'it-it',
      'fr-fr',
      'de-de',
      'de-at',
      'de-be',
      'fr-be',
      'nl-be',
      'en-se',
      'de-ch',
      'en-ch',
      'fr-ch',
      'it-ch',
      'de-lu',
      'fr-lu',
      'nl-lu',
      'nl-nl',
      'pt-pt'
    ]
  },
  newsletters: [{
    type: 'newsletter'
  }, {
    type: 'loyaltyNewsletter',
    onlyForLoyaltyMember: true,
    requiredLocales: [
      'en-gb',
      'it-it',
      'fr-fr',
      'de-de',
      'es-es',
      'nl-nl'
    ]
  }],
  interestOnlyForSubscription: {
    requiredLocales: [
      'en-gb',
      'en-dk',
      'es-es',
      'en-ie',
      'it-it',
      'fr-fr',
      'de-de',
      'de-at',
      'de-be',
      'fr-be',
      'nl-be',
      'en-se',
      'de-ch',
      'en-ch',
      'fr-ch',
      'it-ch',
      'de-lu',
      'fr-lu',
      'nl-lu',
      'nl-nl',
      'pt-pt'
    ]
  }
} satisfies PagesConfig['profile']
