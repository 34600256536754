import type { SignInConfig } from '#types/config/components/form/signIn'

export default {
  brandClasses: {
    form: 'grid',
    heading: 'subtitle-1',
    helpText: 'text-xs c-grey-20',
    submitButton: 'mt-6 w-full',
    wrapper: 'space-y-2'
  }
} satisfies SignInConfig
