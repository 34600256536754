<template>
  <video
    v-if="active"
    ref="videoRef"
    v-style="{ 'aspect-ratio': ratio, 'bgi': poster }"
    style="background-position: center; background-repeat: no-repeat"
    :style="`background-size: ${fit}`"
    class="full"
    :autoplay
    :controls
    :muted="autoplay || muted"
    itemscope
    itemtype="https://schema.org/VideoObject"
    playsinline
    preload="metadata"
    poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    data-test-id="base-video"
    @play="onPlay"
    @playing="preventGtmEvent ||= true"
    @pause="onPause"
    @ended="onEnded"
  >
    <template v-for="(content, itemprop) in meta" :key="itemprop">
      <meta v-if="content" :itemprop :content>
    </template>
    <source v-for="(source, key) in sources" v-bind="source" :key>
  </video>
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const props = withDefaults(defineProps<BaseVideoPropType>(), { controls: true, muted: true, fit: 'contain' })

const videoRef = ref<HTMLVideoElement>()
const active = ref(true)
const preventGtmEvent = ref(false)
const paused = ref(!props.autoplay)

const { $gtm, $viewport } = useNuxtApp()
const { breakpoints } = useAppConfig().ds

const src = computed(() => isObject(props.src) ? props.src : { sm: props.src } as Responsive)
const width = computed(() => isObject(props.width) ? props.width : { sm: props.width } as Responsive)
const height = computed(() => isObject(props.height) ? props.height : { sm: props.height } as Responsive)
const poster = computed(() => isObject(props.poster) ? props.poster : { sm: props.poster } as Responsive)

const meta = computed(() => ({
  contentUrl: src.value.sm,
  thumbnailUrl: poster.value.sm,
  datePublished: props.meta?.date,
  uploadDate: props.meta?.date,
  description: props.meta?.description,
  name: props.meta?.title
}))

// Throw error if someone provided a data object with no sm breakpoint
if (!src.value.sm) log.error('Video: src missing')
if (!width.value.sm) log.info(`Video: No width for ${src.value.sm}`)
if (!height.value.sm) log.info(`Video: No height for ${src.value.sm}`)

const ratio = computed(() => Array.from(Object.keys(width.value))
  .reduce((acc, key) => ({ ...acc, [key]: `${width.value[key]} / ${height.value[key]}` }), {} as Responsive))

const sources = computed(() => [...new Set([src.value, width.value, height.value].flatMap(Object.keys))]
  .sort((a, b) => {
    const order = Object.keys(breakpoints).reverse()
    return order.indexOf(a) - order.indexOf(b)
  })
  .map((bp) => ({
    src: src.value?.[bp] || src.value?.sm,
    width: width.value?.[bp] || width.value?.sm,
    height: height.value?.[bp] || height.value?.sm,
    ...(breakpoints[bp] && { media: `(min-width:${breakpoints[bp]}px)` })
  }))
)

const onPlay = (e: Event) => {
  if (!preventGtmEvent.value && !props.autoplay)
    $gtm.push('video.onPlay', e.target, props.meta?.title)
  preventGtmEvent.value = false
  paused.value = false
}

const onPause = (e: Event) => {
  const target = e.target as HTMLVideoElement
  if (!target.seeking && !props.autoplay) {
    preventGtmEvent.value = false
    if (target.duration !== target.currentTime)
      $gtm.push('video.onPause', target, props.meta?.title)
    paused.value = true
  }
}

const onEnded = (e: Event) => {
  $gtm.push('video.onComplete', e.target, props.meta?.title)
}

onMounted(() => {
  // Restarting video on breakpoint change
  watch(() => $viewport.breakpoint, () => {
    active.value = false
    nextTick(() => active.value = true)
  })
})

defineExpose({
  play: () => videoRef.value?.play(),
  pause: () => videoRef.value?.pause(),
  toggle: () => videoRef.value?.paused ? videoRef.value.play() : videoRef.value?.pause(),
  paused,
  src
})
</script>
