import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  benefitRatingsMaxScale: 3,
  brandClasses: {
    ctasWrapper: 'b-t b-grey-70 pb-8 pt-4 space-y-4',
    headingProductName: 'title-2',
    reviewsHeading: 'text-center',
    reviewsSearchSubmit: 'rounded-r-sm bg-brand-2 c-white p-1',
    shopTheLookButton: 'uppercase fw-bold',
    variantSelectionWrapper: 'b-t b-grey-70 pt-4 space-y-4'
  },
  details: {
    ctaSize: {
      sm: 'sm',
      lg: 'md'
    },
    ctaVariant: 'tertiary',
    favoriteCta: {
      iconSize: 'md',
      iconOnly: false,
      variantSelected: 'tertiary-inverse'
    },
    findInStoreCta: {
      showIcon: true
    }
  },
  gallery: {
    loop: true,
    presets: {
      src: {
        width: 650,
        height: 650
      },
      srcZoom: {
        width: 1300,
        height: 1300
      }
    }
  },
  reviews: {
    iconSize: 'lg'
  },
  showAddToCartCta: true,
  sizeChartWrapper: 'modal',
  swatches: {
    collapseThreshold: 24,
    size: {
      sm: 'sm',
      lg: 'md'
    },
    variant: 'round'
  },
  upsells: {
    showButton: false,
    showCheckbox: true,
    showDetails: true
  }
} satisfies RecursivePartial<PdpConfig>
