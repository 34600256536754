import type { RecursivePartial } from '#types/utils'
import type { DashboardConfig } from '#types/config/components/dashboard'

export default {
  brandClasses: {
    header: 'title-4 pb-4 pt-2'
  },
  favorites: {
    image: {
      height: 100,
      width: 100,
    },
    thumbnail: {
      height: 33,
      width: 33,
    },
  }
} satisfies RecursivePartial<DashboardConfig['card']>
