import type { RecursivePartial } from '#types/utils'
import type { CheckboxConfig } from '#types/config/components/vf/checkbox'

export default {
  brandClasses: {
    base: {
      default: 'ring-grey-40',
      selected: 'bg-brand-1 ring-brand-1',
      error: 'ring-red-30',
      disabled: 'bg-grey-90 ring-grey-50',
      selectedDisabled: 'bg-grey-50 ring-grey-50'
    },
    inverse: {
      default: 'ring-grey-60',
      selected: '',
      error: '!ring-red-60',
      disabled: 'bg-grey-20 ring-grey-50',
      selectedDisabled: ''
    }
  }
} satisfies RecursivePartial<CheckboxConfig>
