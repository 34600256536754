<template>
  <div class="relative" data-test-id="base-scroll">
    <div class="relative max-w-full" :class="[{ 'h-full': dir }, classWrapper]" :style="styleWrapper">
      <slot name="prev" v-bind="{ active: state.hasPrev, action: prev }" />
      <div
        ref="container"
        class="vf-yj6gn6 snap"
        :class="[
          {
            'flex-col h-full w-a overflow-x-hidden overflow-y-auto snap-y snap-mandatory': dir,
            'scrollbar-none': !scrollbar,
          },
          classContainer,
        ]"
        :style="styleContainer"
      >
        <slot v-bind="{ active: state.activePage, activeItem: state.activeItem, action: paginate }" />
      </div>
      <slot name="next" v-bind="{ active: state.hasNext, action: next }" />
      <slot name="bottom" />
    </div>
    <slot name="pagination" v-bind="{ active: state.activePage, activeItem: state.activeItem, action: paginate, pages: state.pages }" />
  </div>
</template>

<script lang="ts">
import type { StyleValue } from 'vue'
import type { CSSClass } from '#types/common'
import type { ScrollState } from '#types/components/base/scroll'

// Compatibility DELTA due to rounding issues
const DELTA = 2.5
type Emit = 'init' | 'toPrev' | 'toNext' | 'paginate' | 'scrollStart' | 'scrollEnd'
</script>

<script lang="ts" setup>
const { displacement = 1, ...props } = defineProps<{
  tag?: string
  displacement?: number
  debounce?: string | number
  dir?: 'vertical'
  scrollbar?: boolean
}
& { [K in 'classContainer' | 'classWrapper']?: CSSClass }
& { [K in 'styleContainer' | 'styleWrapper']?: StyleValue }>()
const emit = defineEmits<{
  [K in Emit]: [payload: Readonly<ScrollState>]
}>()
defineSlots<{
  prev: (props: { active: boolean, action: () => void }) => void
  default: (props: { active: number, activeItem: number }) => void
  next: (props: { active: boolean, action: () => void }) => void
  pagination: (props: { active: number, activeItem: number, action: (i: number) => void, pages: number }) => void
  bottom: () => void
}>()
const slots = useSlots()

const events = ['init']

const state = reactive<ScrollState>({
  left: 0,
  width: 0,
  top: 0,
  height: 0,
  pages: 0,
  activePage: 0,
  activeItem: 0,
  scrollWidth: 0,
  scrollHeight: 0,
  hasPrev: false,
  hasNext: false
})

const dirProps = reactive({
  position: props.dir ? 'top' : 'left',
  endPosition: props.dir ? 'bottom' : 'right',
  scroll: props.dir ? 'scrollTop' : 'scrollLeft',
  scrollSize: props.dir ? 'scrollHeight' : 'scrollWidth',
  containerSize: props.dir ? 'clientHeight' : 'clientWidth',
  size: props.dir ? 'height' : 'width'
})
const container = ref<HTMLElement>(null!)
const children = computed(() => container.value?.children || [])
const fullSize = computed(() =>
  children.value[0].getBoundingClientRect()[dirProps.size] === container.value.getBoundingClientRect()[dirProps.size]
)
const { isScrolling, directions, arrivedState } = useScroll(container, {
  onStop,
  onScroll: slots.pagination ? useThrottleFn(onScroll, 100) : undefined
})

function onScroll({ target }: Event | { target: HTMLElement }) {
  const { scrollLeft, clientWidth } = target as HTMLElement
  state.activeItem = Math[directions.left ? 'floor' : 'round'](scrollLeft / clientWidth)
}

function findPrevSlot(x: number): Element | undefined {
  for (const child of children.value) {
    const rect = child.getBoundingClientRect()

    if (rect[dirProps.position] <= x && x <= rect[dirProps.endPosition])
      return child

    if (x <= rect[dirProps.position])
      return child
  }
}

function findNextSlot(x: number): Element | undefined {
  for (const child of children.value) {
    const rect = child.getBoundingClientRect()
    if (rect[dirProps.endPosition] <= x) continue
    else if (rect[dirProps.position] <= x) return child
    if (x <= rect[dirProps.position]) return child
  }
}

function prev() {
  events.push('toPrev')
  if (!state[dirProps.position]) {
    scrollTo(state[dirProps.scrollSize])
    return
  }

  if (!fullSize.value) {
    /**
     * This logic is redundant when item fills whole space
     */
    const left = container.value.getBoundingClientRect()[dirProps.position]
    const x = left + container.value[dirProps.containerSize] * -displacement - DELTA
    const el = findPrevSlot(x)

    if (el) {
      const width = el.getBoundingClientRect()[dirProps.position] - left
      scrollTo(container.value[dirProps.scroll] + width)
      return
    }
  }

  const width = container.value[dirProps.containerSize] * displacement
  scrollTo(container.value[dirProps.scroll] - width)
}

function next() {
  events.push('toNext')
  if (!state.hasNext) {
    scrollToIndex(0)
    return
  }

  const left = container.value.getBoundingClientRect()[dirProps.position]

  const x = left + container.value[dirProps.containerSize] * displacement + DELTA
  const el = findNextSlot(x)
  if (el) {
    const width = el.getBoundingClientRect()[dirProps.position] - left
    if (width > DELTA) {
      scrollTo(container.value[dirProps.scroll] + width)
      return
    }
  }

  const width = container.value[dirProps.containerSize] * displacement
  scrollTo(container.value[dirProps.scroll] + width)
}

function paginate(i: number) {
  events.push('paginate')
  if (i === state.pages - 1) {
    // If last page, always scroll to last item
    scrollToIndex(children.value.length - 1)
    state.activePage = state.pages - 1
  }
  else {
    state.activePage = i
    scrollTo(i * state[dirProps.size])
  }
}

function scrollToIndex(i: number, smooth = true) {
  if (children.value[i]) {
    const rect = children.value[i].getBoundingClientRect()
    const left = rect[dirProps.position] - container.value.getBoundingClientRect()[dirProps.position]
    scrollTo(container.value[dirProps.scroll] + left, smooth)
  }
}

function scrollTo(left: number, smooth = true) {
  emit('scrollStart', readonly(state))
  container.value.scrollTo({
    [dirProps.position]: left,
    behavior: smooth ? 'smooth' : 'auto'
  })
}

function onStop() {
  if (children.value.length <= 1) {
    // @ts-expect-error to fix
    events.length && emit(events.pop()!, readonly(state))
    /**
     * Basically when scroll ends component tries to make the closest left item
     * to touch left edge of viewport with left edge of child
     * But if user scrolls content to the right edge then component tries
     * to make last child touch right edge of viewport with right edge
     * In case when Scroll contains only one item the functionality described above
     * is not need, moreover it works incorrectly
     * So it should be prevented and component should work like regular scrolable wrapper
     */
    return
  }

  emit('scrollEnd', readonly(state))
  function hasNext(): boolean {
    const val = container.value
    return val[dirProps.scrollSize] > val[dirProps.scroll] + val[dirProps.containerSize] + DELTA
  }
  function hasPrev(): boolean {
    if (container.value[dirProps.scroll] === 0) return false

    const containerVWLeft = container.value.getBoundingClientRect()[dirProps.position]
    const firstChildLeft = children.value[0]?.getBoundingClientRect()?.[dirProps.position] ?? 0
    return Math.abs(containerVWLeft - firstChildLeft) >= DELTA
  }
  function getPages(): number {
    const portions = Math.ceil(state[dirProps.scrollSize] / state[dirProps.size])
    const lastPortionSize = state[dirProps.scrollSize] % state[dirProps.size]
    const lastItemSize
          = children.value[children.value.length - 1].getBoundingClientRect()[dirProps.size]
    if (portions && lastPortionSize && Math.floor(lastPortionSize) < Math.floor(lastItemSize)) {
      /**
       * If last portions width is less than last items width
       * Then last portion shouldn't be recognized as full portion of data
       * And amount of pages should be decreased by 1
       *
       * At the same time when scroll tries to scroll to the last page
       * it always tries to scroll to the right edge
       * see paginate() and next()
       */
      return portions - 1
    }
    return portions
  }
  function getActivePage(): number {
    const portions = Math.ceil(state[dirProps.position] / state[dirProps.size])
    const lastPortionSize = state[dirProps.position] % state[dirProps.size]
    const lastItemSize
          = children.value[children.value.length - 1].getBoundingClientRect()[dirProps.size]
    if (portions && lastPortionSize && lastPortionSize < lastItemSize) {
      /**
       * Take a look at getPages()
       * The same reasons
       */
      return portions - 1
    }
    return portions
  }

  state.left = container.value.scrollLeft
  state.width = container.value.getBoundingClientRect().width
  state.scrollWidth = container.value.scrollWidth
  state.top = container.value.scrollTop
  state.height = container.value.clientHeight
  state.scrollHeight = container.value.scrollHeight
  state.hasNext = hasNext()
  state.hasPrev = hasPrev()
  state.pages = getPages()
  state.activePage = getActivePage()
  onScroll({ target: container.value })

  // @ts-expect-error to fix
  while (events.length) emit(events.pop()!, readonly(state))
}

watchEffect(() => {
  Object.assign(dirProps, {
    position: props.dir ? 'top' : 'left',
    endPosition: props.dir ? 'bottom' : 'right',
    scroll: props.dir ? 'scrollTop' : 'scrollLeft',
    scrollSize: props.dir ? 'scrollHeight' : 'scrollWidth',
    containerSize: props.dir ? 'clientHeight' : 'clientWidth',
    size: props.dir ? 'height' : 'width'
  })
})

defineExpose({
  ...toRefs(state),
  container,
  isScrolling,
  directions,
  arrivedState,
  next,
  prev,
  scrollToIndex
})

const updateState = useDebounceFn(() => {
  container.value && onStop()
}, 250)

onMounted(() => {
  onStop()
  window.addEventListener('resize', updateState)
})

onUnmounted(() => window.removeEventListener('resize', updateState))
</script>
