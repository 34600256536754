<template>
  <div ref="mapRef" />
</template>

<script lang="ts" setup>
import type { MapLocation } from '#types/googleMap'

interface ExtendedMarker {
  marker: google.maps.marker.AdvancedMarkerElement
  id: string
}

const props = defineProps<{
  options?: google.maps.MapOptions
  locations?: MapLocation[]
  selectedLocation?: string
  selectedLocationOptions?: google.maps.marker.PinElementOptions
}>()
const emit = defineEmits<{
  markerClick: [id: string]
}>()

const mapRef = ref()
const mapId = useId()
let map: google.maps.Map
const mapMarkers: google.maps.marker.AdvancedMarkerElement[] = []
const extendedMarkers: ExtendedMarker[] = []

const { loadMapsAndMarker } = useGoogleMaps()

const updateMarkerLooks = (
  marker: google.maps.marker.AdvancedMarkerElement,
  options?: google.maps.marker.PinElementOptions
) => {
  const pinGlyph = new google.maps.marker.PinElement(options)
  marker.content = pinGlyph.element
}
const selectMarker = (marker?: google.maps.marker.AdvancedMarkerElement) => {
  extendedMarkers.forEach((extendedMarker) => updateMarkerLooks(extendedMarker.marker))
  if (marker && props.selectedLocationOptions) updateMarkerLooks(marker, props.selectedLocationOptions)
  if (marker?.position) map.setCenter(marker.position)
}

const createMarkersFromLocations = () => {
  mapMarkers.forEach((marker) => {
    google.maps.event.clearInstanceListeners(marker)
    marker.map = null
  })
  mapMarkers.length = 0
  extendedMarkers.length = 0

  const latLngBounds = new google.maps.LatLngBounds()
  props.locations?.forEach((location) => {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: new google.maps.LatLng(location.lat, location.lng),
      map,
      title: location.title
    })
    if (marker.position) latLngBounds.extend(marker.position)

    const extendedMarker = { marker, id: location.id }
    extendedMarkers.push(extendedMarker)
    mapMarkers.push(marker)
    google.maps.event.addListener(marker, 'click', () => {
      emit('markerClick', extendedMarker.id)
    })
  })
  map.setCenter(latLngBounds.getCenter())
  map.fitBounds(latLngBounds)
}
onMounted(async () => {
  await loadMapsAndMarker()
  map = new google.maps.Map(mapRef.value, { zoom: 10, mapId, ...props.options })
  createMarkersFromLocations()

  watch(() => props.locations, () => {
    createMarkersFromLocations()
  })

  watch(() => props.selectedLocation, () => {
    const marker = extendedMarkers.find((marker) => marker.id === props.selectedLocation)?.marker
    selectMarker(marker)
  })
})
</script>
