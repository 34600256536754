import type { StoreIdsConfig } from '#types/config/storeIdsConfig'

export default {
  storeId: {
    at: 7113,
    be: 7112,
    ch: 7119,
    de: 7107,
    dk: 7116,
    es: 7109,
    fr: 7106,
    gb: 7105,
    ie: 7114,
    it: 7108,
    lu: 7120,
    nl: 7110,
    pl: 7020,
    pt: 7117,
    se: 7115,
    us: 7101
  }
} as StoreIdsConfig
