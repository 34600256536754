import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    layout: 'text',
    logo: {
      type: 'inline'
    },
    text: {
      color: 'monochrome',
      size: 13
    }
  }
} satisfies RecursivePartial<PaypalConfig['payments']>
