export enum AccountNavItemType {
  addressBook,
  creditCards,
  favorites,
  giftCards,
  memberRewards,
  myAccount,
  myOrders,
  myProfile,
  signOut
}
