import type { RecursivePartial } from '#types/utils'
import type { FavoritesConfig } from '#types/config/components/favorites'

export default {
  buttonVariants: {
    createAccount: 'secondary'
  },
  list: {
    productsPerPage: 48
  }
} satisfies RecursivePartial<FavoritesConfig>
