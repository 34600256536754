import type { TabsConfig } from '#types/config/components/vf/tabs'

export default {
  brandClasses: {
    primary: {
      triggerContainer: ['mb-0.5 i-flex b-b b-grey-70 min-w-full'],
      trigger: ['relative b-b-4 b-transparent py-1 text-sm -mb-0.5 ws-nowrap'],
      triggerActive: ['!b-b-brand-2']
    }
  }
} as Partial<TabsConfig>
