/**
 * Filters object from unneeded key/values based on provided function.
 * @param obj - source object
 * @param fn - callback function which defines the condition to filter by, defaults to Boolean constructor function
 * @returns - filtered object
 * @example
 * filterObject({ sm: {color: 'green'}, md: {color: 'red'}, lg: {color: 'red'} }, ({color}) => color !== 'red')
 * returns { sm: {color: 'green'} }
 * @category Utils
 */
export const filterObject = <T extends Record<string, any>>(obj: T, fn: (v: any, k: string) => boolean = Boolean): T =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v && fn(v, k))) as T
