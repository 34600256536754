import type { FiltersConfig } from '#types/config/components/dialog/filters'

export default {
  brandClasses: {
    heading: 'elevate-title-3'
  },
  seeResultsCta: {
    size: 'xs'
  }
} satisfies FiltersConfig
