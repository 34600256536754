import type { FormConfig } from '#types/config/components/form'

/*
* The collection person details variation is on EMEA country - GLOBAL15-77011:
* name - all countries
* mobile - UK and France
* email - France only
* contact email - For guest user only, except France
* For example in Belgium, we only require the name and that is all we display across all pages,
* whether it's the user or someone else collecting, or the user is guest or logged in.
*/
export default {
  mobilePhone: {
    requiredLocales: [
      'en-gb',
      'fr-fr'
    ]
  },
  email: {
    requiredLocales: ['fr-fr']
  },
  collectionContact: {
    requiredLocales: [
      'en-gb',
      'en-dk',
      'es-es',
      'en-ie',
      'it-it',
      'de-de',
      'de-at',
      'de-be',
      'fr-be',
      'nl-be',
      'en-se',
      'de-ch',
      'en-ch',
      'fr-ch',
      'it-ch',
      'de-lu',
      'fr-lu',
      'nl-lu',
      'nl-nl',
      'en-pt',
      'pt-pt'
    ]
  },
  deliveryTimes: {
    pudo: '4-5',
    ownstore: '1-2'
  },
  checkCollectionPhone: {
    requiredLocales: ['fr-fr']
  }
} satisfies FormConfig['collectionPoint']
