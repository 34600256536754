interface ScrollToElementOptions {
  parent?: HTMLElement
  behavior?: ScrollBehavior
  offset?: number
  direction?: 'left' | 'top'
  isCustomSelector?: boolean
}

const getElementHeight = (selector: string): number =>
  (document.querySelector(selector) as HTMLElement)?.offsetHeight || 0

/**
 * Scrolls to element within the document or a parent element.
 * @param {string} selector - Selector of the element which to scroll to.
 * @param {ScrollToElementOptions} options - Parent element to query within & scrolling behavior 'smooth' | 'auto' & scrolling direction 'left'(horizontal) | 'top' (vertical).
 * @category Utils
 */
export const scrollToElement = (selector: string | HTMLElement, options: ScrollToElementOptions = {}) => {
  const {
    parent = document,
    behavior = 'smooth',
    offset = getElementHeight('[data-scroll-el="header"]') + getElementHeight('[data-scroll-el="promo-bar"]'),
    direction = 'top',
    isCustomSelector
  } = options

  const node = isString(selector)
    ? parent.querySelector<HTMLElement>(isCustomSelector ? selector : `[data-scroll-el="${selector}"]`)
    : selector

  if (!node) {
    console.error(`Could not find element ${selector} in the DOM to perform scroll.`)
    return
  }

  const scrollOffset = `offset${direction === 'top' ? 'Top' : 'Left'}`
  const scrollDirection = `scroll${direction === 'top' ? 'Y' : 'X'}`
  const scrollParent = getScrollParent(node)
  const nodeScrollY = scrollParent === window
    ? window[scrollDirection] + node.getBoundingClientRect()[direction]
    : node[scrollOffset]
  return scrollParent.scrollTo({
    [direction]: nodeScrollY - offset,
    behavior
  })
}
