import type { MaybeElement } from '@vueuse/core'

/**
 * Checks if parent DOM element contains specific child.
 * @category Utils
 */
export const containsChildElement = (
  parent: MaybeElement,
  child: MaybeElement
) => {
  if (!parent || !child) return false
  const parentEl = (isHTMLElement(parent) || isSVGElement(parent)) ? parent : parent.$el
  const childEl = (isHTMLElement(child) || isSVGElement(child)) ? child : child.$el

  return parentEl.nodeType && childEl.nodeType && parentEl.contains(childEl)
}
