import type { GiftCardConfig } from '#types/config/components/form/giftCard'
import type { RecursivePartial } from '#types/utils'

export default {
  messageMaxLength: 254,
  swatches: {
    collapseThreshold: 24,
    showCollapseIcon: true,
    size: { sm: 'md', lg: 'lg' },
    variant: 'round'
  }
} satisfies RecursivePartial<GiftCardConfig>
