/**
 * Finds the parent element of `element`, given that
 * the element is not null or the position of the parent element is static and the element is excluded, and
 * returns the parent element if the overflow and overflowY of the parent element are auto or scroll.
 * @category Utils
 */
export function getScrollParent(el: HTMLElement) {
  if (!el) return window
  let style = getComputedStyle(el)
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = /auto|scroll/

  if (style.position === 'fixed') return document.body

  let parent = el
  while (parent.parentElement) {
    parent = parent.parentElement
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') continue
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent
  }

  return window
}
