import type { QuickshopConfig } from '#types/config/components/dialog/quickshop'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    label: 'fw-bold',
    stickyFooter: 'space-y-4',
    wrapperInline: 'lg:(absolute -top-8 -inset-x-2 z-3 bg-white pt-8 pb-4 px-2)',
    wrapperModal: 'lg:(grid gap-4)'
  },
  brandStyles: {
    wrapper: {
      'grid-cols': {
        lg: 'minmax(0, 1fr) minmax(0, 0.85fr)'
      }
    }
  },
  carousel: {
    brandClasses: {
      container: 'gap-4'
    },
    pagination: true
  },
  viewDetailsVariant: 'tertiary'
} satisfies RecursivePartial<QuickshopConfig>
