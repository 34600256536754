import type { CheckboxConfig } from '#types/config/components/vf/checkbox'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    base: {
      selected: 'bg-brand-1 ring-brand-1 c-black'
    },
    inverse: {
      selected: 'bg-brand-1 ring-brand-1 c-black'
    }
  }
} satisfies RecursivePartial<CheckboxConfig>
