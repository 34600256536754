import { LogLevels } from '#root/enums/logger'
import type { LogConfig, LogMeta, Logger } from '#types/logger'

/**
 * Feature flags can only be properly targeted and used when in the context of a user request.
 * In all other cases, we wouldn't have access to the composable, so we can consider
 * this as an indicator that the app-level configurations can be used.
 * @returns The log level indicated in the feature flag; falls back to 'passthrough',which means that the app configuration will be used
 * @category Utils
 */
const getLogLevelFeatureFlag = () => {
  try {
    return process.env.LOGGER_LEVEL ?? useFeatureFlags().logLevel
  }
  catch (e) { }

  return LogLevels.passthrough
}

/**
 * Retrieves the log configuration.
 * @returns The log configuration.
 * @category Utils
 */
export const getLogConfig = (): LogConfig => {
  try {
    const flagLevel = getLogLevelFeatureFlag()

    const {
      brand,
      gitHash,
      targetEnv,
      features: { logger: { allowedChannels, enabledChannels, level } }
    } = useRuntimeConfig().public

    return {
      brand,
      gitHash,
      targetEnv,
      allowedChannels,
      enabledChannels,
      level: (flagLevel && +flagLevel > LogLevels.passthrough) ? LogLevels[flagLevel] : level
    }
  }
  catch (e) {
    // This fallback will be used in cases when the Nuxt app isn't available,
    // such as for build-time and startup-time functionalities,
    // and for ones outside setup events - these should all be server-side

    return {
      enabledChannels: process.env.LOGGER_ENABLED_CHANNELS === 'true',
      allowedChannels: process.env.LOGGER_ALLOWED_CHANNELS?.split?.(',') || [],
      level: process.env.LOGGER_LEVEL ?? LogLevels[LogLevels.debug]
    }
  }
}

/**
 * Filters log messages based on log level, metadata, and configuration.
 * @param logLevel The log level of the message.
 * @param meta Optional metadata associated with the log message.
 * @param config Optional log configuration.
 * @returns A boolean indicating whether the log message should be filtered.
 * @category Utils
 */
const filter = (logLevel: string, meta?: LogMeta, config?: LogConfig) => {
  const { allowedChannels, enabledChannels, level } = config || getLogConfig()
  const { channel } = meta || {}

  if (channel && enabledChannels && !allowedChannels.includes(channel))
    return false

  return LogLevels[logLevel] <= LogLevels[level]
}

/**
 * Formats log messages with metadata and configuration.
 * @param level The log level of the message.
 * @param message The log message.
 * @param meta Optional metadata associated with the log message.
 * @param config Optional log configuration.
 * @returns The formatted log message.
 * @category Utils
 */
const format = (level: string, message: string, meta: LogMeta = {}, config?: LogConfig) => {
  const { brand, gitHash, targetEnv } = config || getLogConfig()
  const { api = {} } = meta

  // Based on https://digital.vfc.com/wiki/display/CLOUD/Application+Logging+Structure
  const result = {
    message,
    service: brand,
    app_environment: targetEnv,
    code_hash: gitHash,
    severity: level.toUpperCase(),
    stack_trace: meta.stackTrace,
    content_type: api.contentType,
    status_code: api.statusCode,
    response_time: api.responseTime,
    host: api.host,
    request_method: api.method,
    request_path: api.path,
    request_query_params: api.queryParams,
    request_query_string: api.queryString,
    baseUrl: api.baseUrl,
    referrer: api.referrer,
    transactionId: api.transactionId
  }

  // eslint-disable-next-line nuxt/prefer-import-meta
  return process.server ? JSON.stringify(result) : result
}

/**
 * Logs messages with the provided level, message, metadata, and configuration.
 * @param level The log level of the message.
 * @param message The log message.
 * @param meta Optional metadata associated with the log message.
 * @param config Optional log configuration.
 * @category Utils
 */
const base = (level: string, message: string, meta?: LogMeta, config?: LogConfig) => {
  if (!filter(level, meta, config)) return

  let method = level

  // All other log levels have a corresponding console method
  if (level === LogLevels[LogLevels.silly] || level === LogLevels[LogLevels.verbose])
    method = LogLevels[LogLevels.debug]

  console[method](format(level, message, meta, config))
}

/**
 * Logger object providing logging functions for each log level.
 * @category Utils
 */
export const log = {
  ...Object.keys(LogLevels).reduce((acc, level) => ({
    ...acc,
    // The config override can be used for cases in which both the Nuxt app and the env variables are inaccessible
    [level]: (message: string, meta?: LogMeta, config?: LogConfig) => base(level, message, meta, config)
  }), {})
} as Logger
