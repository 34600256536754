import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  defaultSearchTerm: 'Timberland',
  brandClasses: {
    suggestions: {
      title: 'subtitle-4 md:subtitle-3'
    }
  }
} satisfies RecursivePartial<VfConfig['search']>
