import type { CheckoutConfig } from '#types/config/checkout'

export default {
  NORA: {
    siteId: {
      DEV: '545d6320e421',
      QA: '545d6320e421',
      PREPROD: '545d6320e421',
      PROD: 'a4b3197a02d5'
    }
  }

} satisfies CheckoutConfig['forter']
