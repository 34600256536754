import brs from '#core/design-system/breakpoints.json'

/**
 * Composes responsive grid classes.
 * @param prop - object with breakpoints and values
 * @param base - base of the class that will be returned
 * @param ignoreValue - value from the object will be ignored
 * @example
 * getResponsiveClasses({ sm: 2, md: 3 }, 'cols')
 * returns 'cols-2 md:cols-3'
 * @example
 * getResponsiveClasses({ sm: 2, md: 3 }, 'grid', true)
 * returns 'grid md:grid'
 * @category Utils
 */
export const getResponsiveClasses = (
  prop: Record<string, any> | string | number | undefined,
  base: string,
  ignoreValue = false
): string => {
  if (typeof prop === 'object') {
    return Object.entries(prop)
      .reduce((res, [br, val]): string => {
        if (!val || !(br in brs)) return res
        // Breakpoint with 0 value will be treated as base class (mobile first)
        const prefix = brs[br] ? `${br}:` : ''
        const postfix = ignoreValue ? '' : `-${val}`
        return `${res}${prefix}${base}${postfix} `
      }, '')
      .trim()
  }
  return prop ? `${base}-${prop}` : ''
}
