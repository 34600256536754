import type { RecursivePartial } from '#types/utils'
import type { MiniCartConfig } from '#types/config/components/dialog/miniCart'

export default {
  brandClasses: {
    articles: 'pb-8 px-4',
    bonus: 'text-sm uppercase fw-bold',
    header: {
      separator: 'b-l pl-1 b-grey-10'
    },
    item: 'line-clamp-3 text-sm fw-bold'
  },
} satisfies RecursivePartial<MiniCartConfig>
