import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    label: 'text-sm',
    ring: 'ring-grey-40',
    swatch: 'h-8 w-8',
    swatchDisabled: 'op-50',
    swatchInner: 'inset-1',
    swatchInnerDisabled: 'inset-1'
  },
  hasIcon: true
} satisfies VfConfig['colorFilter']
