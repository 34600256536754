import { isObject } from '../is'

function isMergeable(origin, patch) {
  return (isObject(origin) && isObject(patch))
}

/**
 * Deep merge two objects.
 * @param ...objects - List of objects to merge
 * @param modifier - Optional modifier function to modify the merge behaviour.
 *                  If provided, it will be called for each key with the values of the two objects.
 *                  It returns a value, that value will be used.
 *
 * @example
 * const a = { foo: { bar: 1, baz: 3 } }
 * const b = { foo: { baz: 2 } }
 * const c = mergeDeep(a, b)
 * // c = { foo: { bar: 1, baz: 2 } }
 *
 * const d = mergeDeep(a, b, (a, b) => a + b)
 * // d = { foo: { bar: 1, baz: 5 } }
 */

export function mergeDeep(...args: any[]) {
  const [origin, patch, ...rest] = args.filter(Boolean)
  const modifier = typeof rest.at(-1) === 'function' ? rest.pop() : undefined

  if (!isMergeable(origin, patch)) return patch !== undefined ? patch : origin

  const output = { ...origin }

  Object.keys(patch).forEach((key) => {
    if (isMergeable(origin[key], patch[key])) {
      output[key] = mergeDeep(origin[key], patch[key], modifier)
    }
    else {
      const value = modifier ? modifier(origin[key], patch[key]) : patch[key]
      Object.assign(output, { [key]: value !== undefined ? value : patch[key] })
    }
  })

  return rest.length > 0 ? mergeDeep(output, ...rest, modifier) : output
}

export function assignDeep(...args: any[]) {
  const [origin, patch, ...rest] = args.filter(Boolean)
  const modifier = typeof rest.at(-1) === 'function' ? rest.pop() : undefined

  if (!isMergeable(origin, patch)) return patch !== undefined ? patch : origin

  Object.keys(patch).forEach((key) => {
    if (isMergeable(origin[key], patch[key])) {
      origin[key] = assignDeep(origin[key], patch[key], modifier)
    }
    else {
      const value = modifier ? modifier(origin[key], patch[key]) : patch[key]
      Object.assign(origin, { [key]: value !== undefined ? value : patch[key] })
    }
  })

  return rest.length > 0 ? assignDeep(origin, ...rest, modifier) : origin
}
