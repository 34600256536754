import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  linkIcon: 'chevron',
  closeIconSize: 'md',
  brandClasses: {
    nav: 'mt-0.5',
    mobileBackground: 'bg-grey-90'
  }
} satisfies RecursivePartial<CMSConfig['megaMenu']>
