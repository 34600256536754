export default (host: string) => ({
  '@id': 'organization',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'CH',
    'addressLocality': 'Stabio',
    'addressRegion': 'Ticino',
    'postalCode': '6855',
    'streetAddress': 'Via Laveggio 5'
  },
  'areaServed': 'IT',
  'brand': {
    '@type': 'Brand',
    '@id': `https://${host}/it-it#brand`,
    'name': 'Timberland',
    'description': 'Timberland è per gli audaci. Il nostro obiettivo è ispirare e preparare una nuova generazione di amanti dell\'avventura a mettersi in gioco e a collaborare per far progredire il mondo.',
    'url': `https://${host}/it-it`,
    'image': `https://${host}/img/logo50.svg`
  },
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'telephone': '+39 800 793 760',
      'contactType': 'Telephone',
      'areaServed': 'IT',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CET'
    },
    {
      '@type': 'ContactPoint',
      'contactType': 'Chat',
      'areaServed': 'IT',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CET',
      'url': `https://${host}/it-it`
    }
  ],
  'logo': {
    '@id': '#logo',
    'caption': 'Timberland',
    'contentUrl': `https://${host}/img/logo50.svg`,
    'url': `https://${host}/img/logo50.svg`
  },
  'name': 'Timberland',
  'parentOrganization': 'VF Corporation (VFC)',
  'sameAs': [
    'https://www.youtube.com/timberland',
    'https://www.instagram.com/timberland',
    'https://www.facebook.com/timberland'
  ],
  'url': `https://${host}/it-it`
})
