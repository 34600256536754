export default (host: string) => ({
  '@id': 'organization',
  'areaServed': 'CA',
  'brand': {
    '@type': 'Brand',
    '@id': `https://${host}/en-ca#brand`,
    'name': 'Timberland',
    'description': 'Timberland CA is Built for the Bold. We inspire and equip adventurers to get outside, work hard and move the world forward.',
    'url': `https://${host}/en-ca`,
    'image': `https://${host}/img/logo50.svg`
  },
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'telephone': '+1-833-817-1812',
      'contactType': 'Telephone',
      'areaServed': 'CA',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 08:30-17:00 EST'
    },
    {
      '@type': 'ContactPoint',
      'contactType': 'Chat',
      'areaServed': 'CA',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 08:30-17:00 EST',
      'url': `https://${host}/en-ca`
    }
  ],
  'logo': {
    '@id': '#logo',
    'caption': 'Timberland',
    'contentUrl': `https://${host}/img/logo50.svg`,
    'url': `https://${host}/img/logo50.svg`
  },
  'name': 'Timberland',
  'parentOrganization': 'VF Corporation (VFC)',
  'sameAs': [
    'https://www.youtube.com/timberland',
    'https://www.instagram.com/timberland',
    'https://www.facebook.com/timberland'
  ],
  'url': `https://${host}/en-ca`
})
