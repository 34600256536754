import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    columns: 'mt-10',
    link: 'px-2',
    menu: 'rounded-lg bg-white px-4 py-6 shadow-sm',
    popoverContent: 'pt-7 px-4',
    title: '',
    underline: 'b-b b-brand-2',
    underlineActive: '',
    shopAll: 'text-lg'
  },
  hideShadow: true,
  triggerOnClick: true,
  underlineOffset: '0'
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
