export default (host: string) => ({
  '@id': 'organization',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'CH',
    'addressLocality': 'Stabio',
    'addressRegion': 'Ticino',
    'postalCode': '6855',
    'streetAddress': 'Via Laveggio 5'
  },
  'areaServed': 'DK',
  'brand': {
    '@type': 'Brand',
    '@id': `https://${host}/en-dk#brand`,
    'name': 'Timberland',
    'description': 'Timberland is Built for the Bold. We inspire and equip adventurers to get outside, work hard and move the world forward.',
    'url': `https://${host}/en-dk`,
    'image': `https://${host}/img/logo50.svg`
  },
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'telephone': '+45 80252978',
      'contactType': 'Telephone',
      'areaServed': 'DK',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CET'
    },
    {
      '@type': 'ContactPoint',
      'contactType': 'Chat',
      'areaServed': 'DK',
      'hoursAvailable': 'Mo,Tu,We,Th,Fr 09:00-19:00 CET',
      'url': `https://${host}/en-dk`
    }
  ],
  'logo': {
    '@id': '#logo',
    'caption': 'Timberland',
    'contentUrl': `https://${host}/img/logo50.svg`,
    'url': `https://${host}/img/logo50.svg`
  },
  'name': 'Timberland',
  'parentOrganization': 'VF Corporation (VFC)',
  'sameAs': [
    'https://www.youtube.com/timberland',
    'https://www.instagram.com/timberland',
    'https://www.facebook.com/timberland'
  ],
  'url': `https://${host}/en-dk`
})
