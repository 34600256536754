import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  badgePosition: 'top',
  brandClasses: {
    eyebrow: 'mt-1',
    gallery: 'mb-1',
    ratingWrapper: 'mt-1',
    title: 'mt-1 text-sm'
  },
  maxCarouselItems: 5,
  maxGalleryItems: 3,
  mediaSize: {
    width: 340,
    height: 340
  },
  quickshopCta: {
    size: 'xs',
    variant: 'tertiary'
  }
} satisfies RecursivePartial<ProductConfig['card']>
